import Swiper from "swiper/bundle";
import { select } from "./Helper";





/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          Get Direction Method for Swiper
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

const getDirection = () => {
  let windowWidth = window.innerWidth;
  let direction = window.innerWidth <= 760 ? "horizontal" : "vertical";
  return direction;
}





/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
                   Slider  2  
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

var swiperFuro;

function createSwiperFuro(swiperElement,slidesPerView) {
  console.info('createSwiperFuro');
  
  var auxOp = {
    // direction: "vertical",
    loop: true,
    grabCursor: false,
    parallax: true,
    effect: "slide",
    speed: 500,

    // preloadImages: true,
        
    auxOp,

    autoplay: {
      delay: 3000,
    },

    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    breakpoints: {
      1024: {
        speed: 1000
      }
    },



    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
    },

    navigation:{
      nextEl:".right",
      prevEl:".left"

    },

    // pagination: {
      
    //   el: ".swiper-paginationF",
    //   type: "bullets",
    //   clickable: true,
    // },
    scrollbar: {
      el: ".swiper-scrollbar",
    },
  }
  
  if(slidesPerView!=1){
    auxOp.slidesPerView = "auto";
    auxOp.centeredSlides = true;
    auxOp.spaceBetween = 0;
  }
  swiperFuro = new Swiper(swiperElement, auxOp);
  console.info(swiperFuro);
}

function destroySwiperFuro() {
  console.info('destroySwiperFuro');
  swiperFuro.destroy(true, true);
}







/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Create & Destroy Swiper On Barba View
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/


var counterSwiper = 1;
export const swiperView = {

  namespace: "Home",
  

  afterEnter() {
    
    if(counterSwiper<=0){
      const svf = select(":not(.home-page) .project-slider:last-child .swiper-container");
      const svf_home = select(".home-page .project-slider:last-child .swiper-container");
      
      svf && createSwiperFuro(svf,1.2);
      svf_home && createSwiperFuro(svf_home,1);
    }else{
      setTimeout(function(){
        const svf = select(":not(.home-page) .project-slider:last-child .swiper-container");
        const svf_home = select(".home-page .project-slider:last-child .swiper-container");
        
        svf && createSwiperFuro(svf,1.2);
        svf_home && createSwiperFuro(svf_home,1);

        window.dispatchEvent(new Event('resize'));
      },2000);
    }

    setTimeout(function(){
      window.dispatchEvent(new Event('resize'));
    },500);
    setTimeout(function(){
      window.dispatchEvent(new Event('resize'));
    },1000);
    setTimeout(function(){
      window.dispatchEvent(new Event('resize'));
    },1500);
    setTimeout(function(){
      window.dispatchEvent(new Event('resize'));
    },2000);
    setTimeout(function(){
      window.dispatchEvent(new Event('resize'));
    },2500);
    
    counterSwiper++;
    
  },




  afterLeave() {

    const svfd = select(".project-slider .swiper-container");
    svfd && destroySwiperFuro();
    
  },


};
